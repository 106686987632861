import React from 'react';
import './Loader.scss';

const Loader = () => {
    return(
        <div className={"Loader"}>

        </div>
    )
}

export default Loader;